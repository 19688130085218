import React from 'react';
import { Box, IconButton, TextField, Typography, styled } from '@mui/material';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { CameraViewConstants, CommonConstants } from '@root/utils/constants';
import { CameraViewActionsProps } from './CameraViewActions.types';
import ToolbarButton from '@root/pages/ScenePage/components/ToolbarButton';
import { ReloadIcon } from '@root/assets/icons/ReloadIcon';
import { RotationAxis } from '@root/utils/constants/enums';

const CameraViewActions = ({
    activeModel,
    modelDeleteIsEnable,
    positionDeleteIsEnable,
    positionLabel,
    currentModelIndex,
    isDisabled,
    currentAxis,
    rotationAngle,
    setCurrentAxis,
    rotateModel,
    setRotationAngle,
    moveToPreviousModel,
    moveToNextModel,
    moveToPreviousPosition,
    moveToNextPosition,
    deleteModel,
    addModel,
    savePosition,
    deletePosition,
}: CameraViewActionsProps): React.JSX.Element => {

    const moveToNextAxis = (): void => {
        let axisIndex = 0;

        if (currentAxis === Object.values(RotationAxis).length - 1) {
            axisIndex = 0;
        } else {
            axisIndex = currentAxis + 1;
        }

        setCurrentAxis(axisIndex);
    };

    const moveToPreviousAxis = (): void => {        
        let axisIndex = 0;

        if (currentAxis === 0) {
            axisIndex = Object.values(RotationAxis).length - 1;
        } else {
            axisIndex = currentAxis - 1;
        }

        setCurrentAxis(axisIndex);
    };
    
    const handleRotationValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!Number.isInteger(+event.target.value) || +event.target.value > 360 || +event.target.value < 0) {
           return;
        }

        setRotationAngle(+event.target.value);
    };

    return (
    <ActionsSection>
        <ActionRow>
            <StyledLabel>{'3D model :'}</StyledLabel>
            <SelectWithArrows>
            <ActionButton
                onClick={moveToPreviousModel}
                disabled={isDisabled}
                disableRipple>
                <ArrowBackIosNewRounded />
            </ActionButton>
            { activeModel ?
                <>
                    <InfoLabel>
                        { activeModel.name !== CameraViewConstants.DEFAULT_MODEL_NAME ?
                            `${CameraViewConstants.CUSTOM_CAMERA_VIEW_MODEL_PREFIX} ${currentModelIndex}` :
                            activeModel.name
                        }
                    </InfoLabel>
                    { modelDeleteIsEnable && 
                        <ActionIcon onClick={deleteModel} disableRipple><DeleteImageIcon /></ActionIcon>
                    }
                </>
                :
                <>
                    <label htmlFor="3dModelUpload">
                        <input
                            disabled={isDisabled}
                            style={{ display: 'none' }}
                            id="3dModelUpload"
                            type="file"
                            accept=".fbx, .obj, .gltf, .glb, .stl"
                            onChange={addModel}
                        />
                        <InfoLabel disabled={isDisabled} action={true}>{'+ add new'}</InfoLabel>
                    </label>
                </>
            }
            <ActionButton
                onClick={moveToNextModel}
                disabled={isDisabled}
                disableRipple>
                <ArrowForwardIosRounded />
            </ActionButton>
            </SelectWithArrows>
        </ActionRow>

        <ActionRow>
            <StyledLabel>{'position :'}</StyledLabel>
            <SelectWithArrows>
                <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToPreviousPosition}
                    disableRipple>
                    <ArrowBackIosNewRounded />
                </ActionButton>
                <InfoLabel>
                    { activeModel && positionLabel ? positionLabel : CommonConstants.INFO_NOT_FOUND_LABEL}
                </InfoLabel>
                { positionDeleteIsEnable && 
                    <ActionIcon onClick={deletePosition} disableRipple><DeleteImageIcon /></ActionIcon>
                }
                <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToNextPosition}
                    disableRipple>
                    <ArrowForwardIosRounded />
                </ActionButton>
            </SelectWithArrows>
        </ActionRow>

        <ActionRow>
            <StyledLabel>{'rotate :'}</StyledLabel>
            <SelectWithArrows gapValue='20px'>
            <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToPreviousAxis}
                    disableRipple>
                    <ArrowBackIosNewRounded />
                </ActionButton>
                <InfoLabel>
                        {Object.values(RotationAxis)[currentAxis]}
                </InfoLabel>
                <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToNextAxis}
                    disableRipple>
                    <ArrowForwardIosRounded />
                </ActionButton>
                <RotateInput
                    type="number"
                    disabled={!activeModel || isDisabled}
                    widthValue={'40px'}
                    heightValue={'18px'}
                    value={rotationAngle}
                    onChange={handleRotationValueChange}
                    variant="outlined"
                />
                <ToolbarButton
                    isDisabled={!activeModel || isDisabled}
                    clickEvent={rotateModel}>
                    <ReloadIcon/>
                </ToolbarButton>
            </SelectWithArrows>
        </ActionRow> 
            <ActionRow>
                { !!activeModel && !positionLabel &&
                    <InfoLabel
                        action={!!activeModel && !positionLabel}
                        onClick={savePosition}>
                        {'save position'}
                    </InfoLabel>
                }
            </ActionRow>

    </ActionsSection>
  );
};

export default CameraViewActions;

const ActionsSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'all 0.5s',
    marginTop: '20px'
}));

const ActionRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: '28px',

    '&:hover': {
        'button': {
          opacity: '1 !important',
        }
    }
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.light1,
    fontSize: '16px',
    lineHeight: '18px',
    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },
    minWidth: '77px',
}));

const SelectWithArrows = styled(Box)<{widthValue?: string, gapValue?: string}>(({widthValue, gapValue}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: widthValue || '195px',
    gap: gapValue || '30px',
    marginLeft: '28px'
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    width: '15px',
    height: '15px',
    color: theme.palette.primary.contrastText,
  
    '& svg': {
      width: '15px',
      height: '15px',
    }
  }));

  const ActionIcon = styled(IconButton)(({ theme }) => ({
    width: '21px',
    height: '21px',
    color: theme.palette.primary.contrastText,

    position: 'absolute',
    right: '60px',
    opacity: '0',
  
    '& svg': {
      width: '21px',
      height: '21px',
    }
  }));

  const InfoLabel = styled(Typography)<{action?: boolean, disabled?: boolean}>(({ theme, action, disabled }) => ({
    fontFamily: 'Roboto400',

    ...(!disabled && {
        color: action ? theme.palette.primary.main : theme.palette.primary.contrastText,
        cursor: action ? 'pointer' : 'auto'
    }),

    ...(disabled && {
        color: theme.palette.primary.light1,
        cursor: 'auto'
    }),

    fontSize: '14px',
    lineHeight: '16px',
    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },
    textAlign: 'center'
}));

const RotateInput = styled(TextField)<{widthValue: string, heightValue: string}>(({widthValue, heightValue, theme}) => ({
    display: 'flex',
    alignItems: 'center',

    width: widthValue,
    minHeight: heightValue,

    '& .MuiOutlinedInput-root': {
        gap: '12px',
        width: widthValue,
        minHeight: heightValue,
        fontSize: '14px',
        fontFamily: 'Roboto400',

        padding: '0',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '4px',
        paddingBottom: '4px',
        borderRadius: '6px',

        'fieldset': {
            borderColor: theme.palette.primary.light
        },
        
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light1,
            borderWidth: '1px'
        },
        
        '&.Mui-focused textarea': {
            color: theme.palette.primary.contrastText
        }
    },

    '& input': {
        padding: '0'
    }
}));
