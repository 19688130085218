import React, { useContext, useEffect, useState } from 'react';

import { Box, TextField, Typography, styled } from '@mui/material';

import ActiveModelContext from '../../../../context/ActiveModelContext/ActiveModelContext';
import useAutosave from '../../../../utils/useAutosave.hook';
import SelectCheckbox from '@root/components/SelectCheckbox';
import { ExpandMenuIcon } from '@root/assets/icons/ExpandMenuIcon';
import { ImagesViewType } from '@root/utils/constants/enums';
import { ImageSectionProps } from './types/ImageSection.types';
import { ImageLabel, UploadedRetrainFile } from '@root/types/commonTypes';

const IMAGE_LABEL_MAX_LENGTH = 300;

const ImageSection = ({
    img,
    selectMode,
    isImageSelected,
    selectImage,
    viewType,
    preview = false,
    isRetrain,
}: ImageSectionProps): React.JSX.Element => {
    const {
        activeModelStatus,
        updateLabel,
        updatedLabels,
        setUpdatedLabels,
        uploadedFiles,
        setUploadedFiles
    } = useContext(ActiveModelContext);

    const [imageUrl, setImageUrl] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [isLabelFromImage, setIsLabelFromImage] = useState<boolean>(true);
    const [editMode, setIsEditMode] = useState<boolean>(false);

    const onLabelInputChange = (event:  React.ChangeEvent<HTMLInputElement>): void => {
        setIsLabelFromImage(false);
        setLabel(event.target.value);
    };

    const handleEditMode = (): void => {
        setIsEditMode(!editMode);
    };

    const handleSelectChange = (selected: boolean): void => {
        selectImage(img.Id, selected);
    };

    const getImageName = (value: string): string => {
        const lastIndex = value.lastIndexOf('/');
        if (lastIndex !== -1) {
            const result = value.substring(lastIndex + 1);
            return result;
        }
        return value;
    };

    useEffect(() => {
        if (!img.Id) {
            return;
        }

        setIsLabelFromImage(true);
        setLabel(img.Caption);
    }, [img]);

    useAutosave(
        () => {
            const image: ImageLabel = {
                Id: img.Id,
                Prompt: label,
            };
            updateLabel(image);
        },
        500,
        [label],
        isLabelFromImage || isRetrain,
    );

    useAutosave(
        () => {
            if (typeof img.Id === 'number') {
                const image: ImageLabel = {
                    Id: img.Id,
                    Prompt: label,
                };
                setUpdatedLabels([...updatedLabels.filter((el: ImageLabel) => el.Id !== image.Id), image]);
            } else {
                const fileForUpdate: UploadedRetrainFile = uploadedFiles.find((el: UploadedRetrainFile) => el.Id === img.Id);
                fileForUpdate.Prompt = label;
                setUploadedFiles([...uploadedFiles.filter((el: UploadedRetrainFile) => el.Id !== img.Id), fileForUpdate]);
            }
        },
        500,
        [label],
        isLabelFromImage || !isRetrain,
    );

    useEffect(() => {
        const createdDate = new Date(img.DateCreated);
        setImageUrl(`${(window as any)._env_.REACT_APP_BASE_URL}${img.FileName}?` + createdDate.valueOf());
    }, [img]);

    return (
        <Wrapper view={viewType}>
            <ImageWrapper view={viewType}>
                <img
                    loading="lazy"
                    src={ preview ? img.file : imageUrl }
                />
                <ImageLoader preview={preview && !isRetrain}>
                    <span className="loader"></span>
                </ImageLoader>
                { (!activeModelStatus || isRetrain) && 
                    <ImageCheckbox
                        isHidden={!selectMode}
                        checked={isImageSelected(img.Id)}
                        onChange={handleSelectChange}
                    />
                }
                { viewType === ImagesViewType.Grid && 
                    <ExpandIcon active={editMode} onClick={handleEditMode}></ExpandIcon>
                }
            </ImageWrapper>
                <InfoWrapper view={viewType}>
                    <ImageInfo
                        visible={viewType === ImagesViewType.List || (editMode && viewType === ImagesViewType.Grid)}
                        className={viewType === ImagesViewType.Grid ? 'grid-view' : 'list-view'}>
                        {
                            viewType === ImagesViewType.List && 
                            <FileNameLabel>
                                <InputLabel>{getImageName(img.FileName)}</InputLabel>
                            </FileNameLabel>
                        }
                        <CustomInput
                            helperText={`Symbols left: ${
                                label.length === IMAGE_LABEL_MAX_LENGTH
                                ? '0' :
                                IMAGE_LABEL_MAX_LENGTH - label.length}/${IMAGE_LABEL_MAX_LENGTH}`
                            }
                            inputProps={{ maxLength: IMAGE_LABEL_MAX_LENGTH }}
                            disabled={activeModelStatus && !isRetrain}
                            id={`${img.Id}`}
                            multiline={true}
                            variant="outlined"
                            onChange={onLabelInputChange}
                            value={label}
                            view={viewType}
                        />
                    </ImageInfo>
                </InfoWrapper>
        </Wrapper>
    );
};

export default ImageSection;

const Wrapper = styled(Box)<{view: number}>(({ view }) => ({
    display: 'flex',
    position: 'relative',
    flexDirection: view === ImagesViewType.List ? 'row' : 'column',

    gap:view === ImagesViewType.List ? '13px' : '8px',
    alignItems: 'start',
    objectFit:'contain',

    maxWidth: '100%',
    maxHeight: '100%',
    width: 'max-content'
}));


const ImageInfo = styled(Box)<{visible: boolean}>(({ theme, visible }) => ({
    display: visible ? 'flex' : 'none',
    zIndex: '1',
    backgroundColor:theme.palette.neutrals.main,

    '&.list-view': {
        flexDirection:  'column',
        maxWidth: '245px',
        minHeight: '50px',
        height: '100%',
        '& .MuiOutlinedInput-root': {
            height: '100%',
            alignItems: 'flex-start',
        },
    },

    '&.grid-view': {
        position: 'absolute',
        width: '100%',
        minHeight: '50px',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '15px',
    },

    '& .MuiOutlinedInput-root': {
        minHeight: '25px',

        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',

        '@media (max-width:1500px)': {
            fontSize: '10px',
            lineHeight: '12px',
        },

        padding: '0',

        'fieldset': {
            borderColor: 'transparent',
        },
        
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light1,
            borderWidth: '1px'
        },
        
        '&.Mui-focused textarea': {
            color: theme.palette.primary.contrastText,
        }
    },
}));

const InfoWrapper = styled(Box)<{ view: ImagesViewType }>(({ view }) => ({
    display: 'flex',
    flexDirection:  'column',
    maxWidth: view === ImagesViewType.List ? '245px' : 'auto',
    height: view === ImagesViewType.List ? '100%' : '0px',
}));

const CustomInput = styled(TextField)<{ view: number }>(({ theme, view }) => ({
    margin : view === ImagesViewType.List ? '0' : '12px 16px',
    height: '100%',
    width: view === ImagesViewType.List ? '238px' : '100%',

    '@media (max-width:1600px)': {
        width: view === ImagesViewType.List ? 'auto' : '100%',
    },

    '& textarea': {
        height: '100%',
        borderRadius: '6px',
        padding: view === ImagesViewType.List ? '12px 11px' : '12px 15px',
        color: theme.palette.neutrals.inactive1,
    }
}));

const ImageWrapper = styled(Box)<{ view: number }>(({ view }) => ({
    position: 'relative',
    height: view === ImagesViewType.List ? '268px' : '210px',
    width: view === ImagesViewType.List ? '268px' : 'auto',

    aspectRatio: view === ImagesViewType.List ? '1/1' : 'auto',
    borderRadius: '15px',
    overflow: 'hidden',

    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    '&:hover': {
        '.MuiCheckbox-root': {
            opacity: '1 !important',
        },

        'svg': {
            opacity: '1 !important',
        }
    },

}));

const ImageLoader = styled(Box)<{preview: boolean}>(({ preview }) => ({
    width: '100%',
    height: '100%',

    position: 'absolute',
    top: 0,
    left: 0,

    display: preview ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#00000087',
}));


const ImageCheckbox = styled(SelectCheckbox)<{isHidden: boolean}>(({ isHidden }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: isHidden ? 0 : 1,
    '&:hover': {
        boxShadow: 'none',
    },
}));
  
const ExpandIcon = styled(ExpandMenuIcon)<{active: boolean}>(({ active, theme }) => ({
    position: 'absolute',
    bottom: '2px',
    left: '6px',

    width: '32px',
    height: '32px',
    transform: active ? 'rotate(180deg)' : 'unset',

    transition: 'all 0.5s',
    cursor: 'pointer',
    opacity: active ? 1 : 0,

    backgroundColor: theme.palette.primary.light,
    borderRadius:'50%',

    '& path': {
        stroke: theme.palette.secondary.main,
    }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutrals.inactive1,
    marginRight: '16px',
    alignSelf: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const FileNameLabel = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: '11px'
}));
