import React, { useCallback, useContext, useEffect, useState } from 'react';

import { sortByDateCreated } from '@utils/sortByDateCreated';

import TunesContext from './TunesContext';
import LoaderContext from '../LoaderContext/LoaderContext';
import AuthContext from '../AuthContext/AuthContext';
import ProjectsContext from '../ProjectsContext/ProjectsContext';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import { useTuneApi } from '@hooks/tune/TuneApi';
import { useImageApi } from '@hooks/images/UseImageApi';
import { useMultipleDownloads } from '@hooks/download/UseMultipleDownloads';
import { prefixBaseUrl } from '@utils/url/url';
import { downloadUrl } from '@utils/download/downloadUrl';
import { FolderStructureBlob } from '@utils/blob/FolderStructureBlob';
import LocalStorageContext from '../LocalStorageContext/LocalStorageContext';
import { ModelForGenerationType } from '@root/utils/constants/enums';

const DOWNLOAD_PREFIX = 'depix_designlab';

const TunesContextProvider = ({ children }) => {
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { isSignedIn } = useContext(AuthContext);
    const { storageProjectId } = useContext(LocalStorageContext);
    const { setProjectImagesNumber } = useContext(ProjectsContext);
    const {
        onLikeImage,
        onRemoveLikedImage,
        onDeleteImage,
        onDeleteTune,
        activeProject,
        likedImages,
    } = useContext(ProjectsContext);
    const { createNotification, notificationMessages } = useNotifications();
    const {
        getTunes,
        getTuneStatus: getTuneStatusApi,
        deleteTune: deleteTuneApi,
        newTune,
        getTuneImages,
        newFluxTune
    } = useTuneApi();
    const {
        getImage,
        likeImage: likeImageApi,
        deleteImage: deleteImageApi,
    } = useImageApi();
    const { downloadZip } = useMultipleDownloads();

    const [tunes, setTunes] = useState([]);
    const [activeTuneId, setActiveTuneId] = useState('');
    const [imageCache, setImageCache] = useState([]);
    const [newTuneIds, setNewTuneIds] = useState([]);

    const [selectMode, setSelectMode] = useState(false);
    const [selectedImageIds, setSelectedImageIds] = useState([]);

    const [isFullScreenMode, setIsFullScreenMode] = useState(false);
    const [isFavoriteSectionActive, setIsFavoriteSectionActive] = useState(false);
    const [activeImageSrc, setActiveImageSrc] = useState(null);
    const [tuneErrorMessage, setTuneErrorMessage] = useState('');

    const handleSetTunes = async (projectId) => {
        showLoader();

        if (!projectId) {
            setTunes([]);
        } else {
            const newTunesList = await getTunes(projectId);
            if (newTunesList && !newTunesList?.errorCode) {
                const tunes = sortByDateCreated(newTunesList);

                const tunesWithConfig = await Promise.all(
                    tunes.map(async (tune) => {
                        if (!tune.Configurations || !tune.images) {
                            return tune;
                        }

                        const config = JSON.parse(tune.Configurations);

                        return {
                            ...tune,
                            config,
                        };
                    }),
                );

                setTunes(tunesWithConfig);
            }
        }

        hideLoader();
    };

    const deleteTune = async (id, withLoader = true) => {
        if (withLoader) {
            showLoader();
        }

        const result = await deleteTuneApi(id);
        if (result?.projectImagesNumber) {
            onDeleteTune(id);
            setProjectImagesNumber(result.projectImagesNumber);
        }
        handleSetTunes(activeProject.Id);
        hideLoader();
        return result;
    };

    const createNewTune = async (formData, mode) => {
        showLoader();
        let newTuneData = null;

        if (mode === ModelForGenerationType.FLUX) {
            newTuneData = await newFluxTune(formData);
        } else {
            newTuneData = await newTune(formData);
        }

        if (newTuneData && !newTuneData.errorCode) {
            setProjectImagesNumber(newTuneData.projectImagesNumber);
            setNewTuneIds([...newTuneIds, newTuneData['Tune Id']]);
        }
        hideLoader();
    };

    const getTune = async (id) => {
        const tune = tunes.find((tune) => tune.Id === id);
        if (!tune) {
            return;
        }
        let images = tune.images;
        let config = tune.config;

        if (!images) {
            images = await getTuneImages(id);

            if (images && !images.errorCode) {
                config = JSON.parse(images[0].Configurations);
                setTunes((tunes) =>
                    tunes.map((tuneItem) => ({
                        ...tuneItem,
                        images: tuneItem.Id !== id ? tuneItem.images : images,
                        config: tuneItem.Id !== id ? tuneItem.config : config,
                    })),
                );
            }
        }

        if (images) {
            const isNewTune = newTuneIds.includes(id);
            if (isNewTune && images.length > 0) {
                createNotification(
                    notificationMessages.tune.generating.title,
                    notificationMessages.tune.generating.success,
                    'browser',
                );
                setNewTuneIds((ids) => ids.filter((x) => x !== id));
            }
        }

        return {
            ...tune,
            images,
            config,
        };
    };

    const getImageById = async (id) => {
        const cachedImage = imageCache.find((image) => image.Id === id);
        if (cachedImage) {
            return cachedImage;
        } else {
            const image = await getImage(id);
            if (image?.Id) {
                setImageCache([...imageCache, image]);
                return image;
            }
        }
    };

    const likeImage = async (image) => {
        const liked = await likeImageApi(image.Id);
        if (!liked?.errorCode) {
            if (liked) {
                onLikeImage(image);
            } else {
                onRemoveLikedImage(image.Id);
            }
            return liked;
        } else {
            return false;
        }

    };

    const deleteImage = async (id) => {
        showLoader();
        const tuneWithTargetImage = tunes.find((tune) =>
            tune.images?.find((image) => image.Id === id),
        );
        if (!tuneWithTargetImage) {
            return;
        }
        const isOnlyImageInTune = tuneWithTargetImage.images?.length === 1;
        let success = false;
        if (isOnlyImageInTune) {
            success = await deleteTune(tuneWithTargetImage.Id);
        } else {
            success = await deleteImageApi(id).then((success) => {
                if (success?.projectImagesNumber) {
                    setProjectImagesNumber(success.projectImagesNumber);
                    onDeleteImage(id);
                    let tuneIndex = null;
                    tunes.forEach((tune, i) => {
                        const found = tune.images?.some(
                            (image) => image.Id === id,
                        );
                        if (found) {
                            tuneIndex = i;
                        }
                    });

                    if (tuneIndex !== null) {
                        setTunes((tunes) =>
                            tunes.map((tune) => ({
                                ...tune,
                                images: tune.images?.filter(
                                    (image) => image.Id !== id,
                                ),
                            })),
                        );
                    }
                }
                return success;
            });
        }
        hideLoader();
        return success;
    };

    const selectImage = (imageId, selected) => {
        const foundImage = !!tunes.find((tune) =>
            tune.images?.find((image) => image.Id === imageId),
        );
        if (!foundImage) {
            return;
        }

        if (selected) {
            setSelectedImageIds((ids) => [...ids, imageId]);
        } else {
            setSelectedImageIds((ids) => ids.filter((id) => id !== imageId));
        }
    };

    const selectTune = (tuneId, selected) => {
        const imageIdsInTune = getAllImageIdsInTune(tuneId);

        if (selected) {
            setSelectedImageIds((ids) => [...ids, ...imageIdsInTune]);
        } else {
            setSelectedImageIds((ids) =>
                ids.filter((id) => !imageIdsInTune.includes(id)),
            );
        }
    };

    const selectAll = (selected) => {
        if (selected) {
            const allImageIds = [].concat(
                ...tunes.map((tune) => tune.images?.map((image) => image.Id)),
            );
            setSelectedImageIds(allImageIds);
        } else {
            setSelectedImageIds([]);
        }
    };

    const isImageSelected = (imageId) => {
        return selectedImageIds.includes(imageId);
    };

    const isImageLiked = (imageId) => {
        return likedImages.find(image => image.Id === imageId);
    };

    const isTuneSelected = (tuneId) => {
        const imageIdsInTune = getAllImageIdsInTune(tuneId);
        return !imageIdsInTune.length
            ? false
            : imageIdsInTune.every((id) => isImageSelected(id));
    };

    const isAllSelected = useCallback(() => {
        return !tunes.length
            ? false
            : tunes.every((tune) =>
                  tune.images?.every((image) =>
                      selectedImageIds.includes(image.Id),
                  ),
              );
    }, [tunes, selectedImageIds]);

    const getAllImageIdsInTune = (tuneId) => {
        return (
            tunes
                .find((tune) => tune.Id === tuneId)
                ?.images?.map((image) => image.Id) ?? []
        );
    };

    const deleteSelectedImages = () => {
        const selectedTuneIds = tunes
            .filter((tune) => isTuneSelected(tune.Id))
            .map((tune) => tune.Id);
        const imageIdsInSelectedTune = [].concat(
            ...selectedTuneIds.map((tuneId) => getAllImageIdsInTune(tuneId)),
        );
        const leftoverImageIds = selectedImageIds.filter(
            (id) => !imageIdsInSelectedTune.includes(id),
        );

        selectedTuneIds.forEach((id) => deleteTune(id));
        leftoverImageIds.forEach((id) => deleteImage(id));
        setSelectedImageIds([]);
    };

    const downloadSelectedImages = async () => {
        showLoader();
        const folderName = `${DOWNLOAD_PREFIX}_${activeProject?.Name}`;

        const tuneFolderBlobs = (
            await Promise.all(
                tunes.map(async (tune) => {
                    const hasSelectedImage = tune.images?.some((image) =>
                        selectedImageIds.includes(image.Id),
                    );
                    if (!hasSelectedImage) {
                        return null;
                    }

                    const folderName = `${activeProject?.Name}_${tune.Id}`;
                    const influencePromises = [];
                    if (tune.config?.control_depth_image) {
                        influencePromises.push(
                            downloadUrl(
                                prefixBaseUrl(tune.config?.control_depth_image),
                                'ImageComposition',
                            ),
                        );
                    }
                    if (tune.config?.control_sketch_image) {
                        influencePromises.push(
                            downloadUrl(
                                prefixBaseUrl(tune.config?.control_sketch_image),
                                'Sketches',
                            ),
                        );
                    }

                    const imagePromises = tune.images.map((image) => {
                        if (selectedImageIds.includes(image.Id)) {
                            const name = `${folderName}_${image.Id}_${tune.config.width}_${tune.config.height}`;
                            return downloadUrl(prefixBaseUrl(image.Path), name);
                        }
                    });

                    const tuneFolderBlob = new FolderStructureBlob(folderName);
                    if (influencePromises.length) {
                        const influenceFolderBlob = new FolderStructureBlob(
                            'Influence',
                        );
                        const influenceFiles = await Promise.all(
                            influencePromises,
                        );
                        influenceFolderBlob.withFiles(influenceFiles);
                        tuneFolderBlob.withSubFolder(influenceFolderBlob);
                    }

                    const imageFiles = (
                        await Promise.all(imagePromises)
                    ).filter((imageFile) => !!imageFile);
                    tuneFolderBlob.withFiles(imageFiles);
                    return tuneFolderBlob;
                }),
            )
        ).filter((tuneBlob) => !!tuneBlob);

        const rootFolderBlob = new FolderStructureBlob(folderName);
        rootFolderBlob.withSubFolders(tuneFolderBlobs);
        await downloadZip(rootFolderBlob);
        hideLoader();
    };

    useEffect(() => {
        !isSignedIn && setTunes([]);
    }, [isSignedIn]);

    useEffect(() => {
        setSelectMode(!!selectedImageIds.length);
    }, [selectedImageIds]);

    useEffect(() => {
        setSelectedImageIds([]);
    }, [storageProjectId]);

    return (
        <TunesContext.Provider
            value={{
                handleSetTunes,
                getTuneStatus: getTuneStatusApi,
                createNewTune,
                deleteTune,

                tunes,
                getTune,
                getImageById,
                likeImage,
                deleteImage,

                activeTuneId,
                setActiveTuneId,

                selectMode,
                selectedImageIds,
                selectImage,
                selectTune,
                selectAll,
                isAllSelected,
                isImageSelected,
                isImageLiked,
                isTuneSelected,
                imageSelectionCount: selectedImageIds.length,
                deleteSelectedImages,
                downloadSelectedImages,

                isFullScreenMode,
                setIsFullScreenMode,
                isFavoriteSectionActive,
                setIsFavoriteSectionActive,
                activeImageSrc,
                setActiveImageSrc,
                tuneErrorMessage,
                setTuneErrorMessage
            }}>
            {children}
        </TunesContext.Provider>
    );
};

export default TunesContextProvider;
