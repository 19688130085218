import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CameraViewTranslateIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}>
            <mask id="mask0_339_38" style={{ maskType:'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="currentColor"/>
            </mask>
            <g mask="url(#mask0_339_38)">
            <path d="M7 16L3 12L7 8L8.425 9.4L6.825 11H17.175L15.6 9.4L17 8L21 12L17 16L15.6 14.6L17.175 13H6.825L8.4 14.6L7 16Z" fill="currentColor"/>
            </g>
        </SvgIcon>
    );
};
