import React, { useState} from 'react';
import { Box, styled, Divider } from '@mui/material';
import InputField from '../InputField';
import { ExpandMenuIcon } from '@root/assets/icons/ExpandMenuIcon';
import { PromptSectionProps } from './PromptSection.types';

const PromptSection = ({
  prompt,
  onPromptInputChange,
  negativePrompt,
  onNegativePromptInputChange,
  seed,
  onSeedInputChange,
  onInputSeed,
  promptTooltipText,
  isPromptRequired = true,
  isNegativePromptRequired = true,
  isNegativePromptDisabled = false
}: PromptSectionProps) => {
  const [active, setActive] = useState(false);

  const handleSectionActive = (): void => {
    setActive(!active);
  };
  return (
    <PromptSectionWrapper>
      <PromptSectionContent>
        <InputField
          id="prompt"
          name="prompt"
          isRequired={isPromptRequired}
          isMultiline={true}
          isDisabled={false}
          label="prompt"
          value={prompt}
          onChange={onPromptInputChange}
          tooltipLabel={ promptTooltipText ||
            'In this text input field, enter the words that will influence the formation of the car image' }
        />
        { active && 
          (<>
               { negativePrompt && 
                <InputField
                  id="negativePrompt"
                  name="negativePrompt"
                  isRequired={isNegativePromptRequired}
                  isMultiline={true}
                  isDisabled={isNegativePromptDisabled}
                  label="negative prompt"
                  value={negativePrompt}
                  onChange={onNegativePromptInputChange}
                  tooltipLabel='The description of what you do not want to see in the image.'
                />
               }
            { seed && 
              <InputField
                id="seed"
                name="seed"
                isRequired={true}
                isMultiline={true}
                isDisabled={false}
                label="seed"
                value={seed}
                onChange={onSeedInputChange}
                onKeyDown={onInputSeed}
                tooltipLabel='Indicate the seed number of the generated image. Specify "auto" if you want each subsequent result to differ from the previous one.'
              />
            }
          </>)
        }
      </PromptSectionContent>

      { (negativePrompt || seed)  && 
        <ExpandIconWrapper>
          <ContentDivider/>
          <ExpandIcon active={+active} onClick={handleSectionActive}></ExpandIcon>
          <ContentDivider/>
        </ExpandIconWrapper>
    }
    </PromptSectionWrapper>
  );
};

export default PromptSection;

const PromptSectionWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '300px',
}));

const PromptSectionContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
}));

const ExpandIconWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center'
}));

const ContentDivider = styled(Divider)(({ theme }) => ({
  background: theme.palette.primary.light1,
  width: '130px',
  height: '1px',
  margin: 'auto'
}));

const ExpandIcon = styled(ExpandMenuIcon)<{active: number}>(({ active, theme }) => ({
  transform: active ? 'rotate(180deg)' : 'unset',
  transition: 'all 0.5s',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    borderRadius:'50%',

    '& path': {
      stroke: theme.palette.secondary.main,
    }
},

}));