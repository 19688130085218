export enum CurateJobTypes {
    INPAINTING = 'Inpainting',
    IMAGE_MIXING = 'Image mixing',
    SKETCH_GENERATION = 'Generate from sketch',
    BACKGROUND_REPLACEMENT = 'Background replacement',
    SKETCH_CONVERSION = 'Convert to sketch',
    STYLE_DRIVE = 'Style drive',
    SCENE_SHIFT = 'Scene Shift'
}

export enum CommonJobTypes {
    GENERATION = 'Generation',
    TRAINING = 'Training',
    MODEL_IMPORT = 'Model import',
    MERGING = 'Merging',
    PANORAMA = 'Generating panorama',
    HDR = 'Generating HDR',
    FLUX_GENERATION = 'Flux Generation'
}

export enum SharedJobTypes {
    UPSCALING = 'Upscaling',
    ERASER = 'Eraser'
}

export type JobTypes = CurateJobTypes | CommonJobTypes | SharedJobTypes;
